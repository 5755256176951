@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    overflow-x: hidden;
}

.bg {
    background-image: url(./assets/bg.jpeg);
    background-size: 100% 100%;
    min-height: 100vh;
}

.primary-shadow {
    box-shadow: 2px 0px 15px 7px #0000000b;
}

.border-shadow::before{ border-bottom: 3px solid rgb(0 0 0 / 8%);}